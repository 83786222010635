<template>
	<div class="login">
		<card-component :title="$consts.APP_NAME + ' ログイン'" icon="lock" :has-card-header-background="true">
			<div id="buttonDiv"></div>
			<p v-show="isLoginError" class="login-error">有効なGmailアドレスが取得できませんでした。別のGoogleアカウントをお使いください。</p>
		</card-component>
	</div>
</template>

<style lang="scss" scoped>
.login-error {
	color: red;
	margin-top: 10px;
}
</style>

<script>
import CardComponent from '@/components/CardComponent'
import { loadScript } from 'vue-plugin-load-script'
import jwt_decode from "jwt-decode";

export default {
	name: 'Login',
	components: { CardComponent },
	data () {
		return {
			isLoginError: false,
		}
	},
	mounted() {
		loadScript('https://accounts.google.com/gsi/client').then(() => {
			window.google.accounts.id.initialize({
				client_id: "1090955734787-87457509r4v7ppm49sk86nnsssr77j85.apps.googleusercontent.com",
				callback: this.handleCredentialResponse
			});
			window.google.accounts.id.renderButton(
				document.getElementById("buttonDiv"),
				{ theme: "outline", size: "large" }	// customization attributes
			);
			window.google.accounts.id.prompt(); // also display the One Tap dialog
		});
	},
	methods: {
		handleCredentialResponse: async function(response) {
			this.isLoginError = false;

			let dec = jwt_decode(response.credential);

			if (dec.email.match(/.+@gmail.com$/) === null) {
				this.isLoginError = true;
				return;
			}

			this.$utils.log(dec.email);
			this.$utils.log(dec.sub);

			let loader = this.$buefy.loading.open();

			let data = {
				"email": dec.email,
				"sub": dec.sub,
			};
			this.$utils.log(data);

			let ep = 'users/login';
			this.$utils.log(ep);
			
			try {
				let res = await this.$api.post(ep, data);
				this.$utils.log(res);
				this.$store.commit('user', res.data);
				this.$router.push({ name: 'lp' });
			} catch (error) {
				this.$utils.log(error.response);
				this.$utils.toastError(error.response.data.message);
			} finally {
				loader.close();
			}
		},
	}
}
</script>
