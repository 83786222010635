<template>
	<div id="app">
		<nav-bar @logout="onLogoutClick"/>
		<aside-menu
			:menu="menu"
			:menu-bottom="menuBottom"
			@menu-click="menuClick"
			:class="{'has-secondary':!!menuSecondary}" />
		<aside-menu
			v-if="menuSecondary"
			:menu="menuSecondary"
			:is-secondary="true"
			:label="menuSecondaryLabel"
			:icon="menuSecondaryIcon"
			@menu-click="menuClick"
			@close="menuSecondaryCloseClick"/>
		<router-view/>
		<aside-right/>
		<footer-bar/>
		<overlay/>
	</div>
</template>

<style lang="scss">
$spaces: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50); // 0から50pxまでを用意
$sides: (top, bottom, left, right); // 方向性
@each $space in $spaces {
	@each $side in $sides {
		.m#{str-slice($side, 0, 1)}-#{$space} {
			margin-#{$side}: #{$space}px !important;
		}

		.p#{str-slice($side, 0, 1)}-#{$space} {
			padding-#{$side}: #{$space}px !important;
		}
	}
}

.button {
	width: 120px;
}

::placeholder {
  color: #aaa !important;
}

label.require:after {
	content: ' *';
	color: red;
}

table button.is-small {
	width: 50px;
}

.horizontal-center {
	display: flex;
	align-items: center;
}

.field.is-grouped {
	align-items: center;
}

.form-buttons {
	margin-top: 45px;
}

.field:not(:last-child) {
	margin-bottom: 1.2rem;
}

.slide-enter-active, .slide-leave-active {
	transition: all .2s;
}
.slide-enter, .slide-leave-to {
	height: 0;
}

.help {
	font-size: 1rem;
}
</style>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import Overlay from '@/components/Overlay'
import AsideRight from '@/components/AsideRight'

export default {
	name: 'App',
	components: {
		AsideRight,
		Overlay,
		FooterBar,
		AsideMenu,
		NavBar
	},
	data () {
		return {
			menuSecondary: null,
			menuSecondaryLabel: null,
			menuSecondaryIcon: null
		}
	},
	computed: {
		menu () {
			let menu = [
				'',
				[
					{
						to: '/',
						icon: 'file-code',
						label: 'LP'
					}
				],
			];

			return menu;
		},
		menuBottom () {
			return [
				{
					action: 'logout',
					icon: 'logout',
					label: 'Log out',
					state: 'info'
				}
			]
		},
		...mapState([
			'isOverlayVisible'
		])
	},
	created () {
	},
	mounted () {
	},
	methods: {
		onLogoutClick: function() {
			this.$auth.logout();
			this.$router.push({ name: 'login' });
		},
		menuClick (item) {
			if (item.menuSecondary) {
				this.menuSecondary = item.menuSecondary
				this.menuSecondaryLabel = item.menuSecondaryLabel ? item.menuSecondaryLabel : null
				this.menuSecondaryIcon = item.menuSecondaryIcon ? item.menuSecondaryIcon : null

				this.$store.commit('asideActiveForcedKeyToggle', item)
				this.$store.commit('overlayToggle', true)
			} else if (item.action && item.action === 'logout') {
				this.$buefy.toast.open({
					message: 'Log out clicked',
					type: 'is-danger',
					queue: false
				})
			}
		},
		menuSecondaryCloseClick () {
			this.$store.commit('overlayToggle', false)
		},
		menuSecondaryClose () {
			this.menuSecondary = this.menuSecondaryLabel = this.menuSecondaryIcon = null
			this.$store.commit('asideActiveForcedKeyToggle', null)
		},
		checkForceLogout: async function() {
			let ep = 'users/is_force_logout';
			//this.$utils.log(ep);

			let res = await this.$api.get(ep, null, true);
			//this.$utils.log(res);
			if (res.data.is_force_logout) {
				this.onLogoutClick();
			}
		},

	},
	watch: {
		$route(to, from) {
			//this.$utils.log('watch');
			//this.$utils.log(from);
			//this.$utils.log(to);
			if (to.meta.requireAuth) {
				// ステータスチェック
				this.checkForceLogout();
			}
		},
		isOverlayVisible (newValue) {
			if (!newValue) {
				this.menuSecondaryClose()
			}
		}
	}
}
</script>
