import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/full-page/Login.vue'
import store from '../store/index.js'
import Auth from '../classes/Auth.js'

Vue.use(VueRouter)

const routes = [
	{
		meta: {
			title: 'LP',
			requireAuth: true,
		},
		path: '/',
		name: 'lp',
		component: () => import(/* webpackChunkName: "account" */ '../views/Lp.vue')
	},
	{
		meta: {
			title: 'LP新規作成',
			requireAuth: true,
		},
		path: '/lp-new',
		name: 'lp-new',
		component: () => import(/* webpackChunkName: "account" */ '../views/LpEdit.vue')
	},
	{
		meta: {
			title: 'LP編集',
			requireAuth: true,
		},
		path: '/lp-edit/:lpId',
		name: 'lp-edit',
		component: () => import(/* webpackChunkName: "account" */ '../views/LpEdit.vue')
	},
	//{
	//	meta: {
	//		title: 'ダッシュボード',
	//		requireAuth: true,
	//	},
	//	path: '/',
	//	name: 'dashboard',
	//	// route level code-splitting
	//	// this generates a separate chunk (about.[hash].js) for this route
	//	// which is lazy-loaded when the route is visited.
	//	component: () => import(/* webpackChunkName: "tables" */ '../views/Dashboard.vue')
	//},
	//{
	//	meta: {
	//		title: 'Tables'
	//	},
	//	path: '/tables',
	//	name: 'tables',
	//	// route level code-splitting
	//	// this generates a separate chunk (about.[hash].js) for this route
	//	// which is lazy-loaded when the route is visited.
	//	component: () => import(/* webpackChunkName: "tables" */ '../views/Tables.vue')
	//},
	//{
	//	meta: {
	//		title: 'Forms'
	//	},
	//	path: '/forms',
	//	name: 'forms',
	//	component: () => import(/* webpackChunkName: "forms" */ '../views/Forms.vue')
	//},
	//{
	//	meta: {
	//		title: 'Profile'
	//	},
	//	path: '/profile',
	//	name: 'profile',
	//	component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue')
	//},
	//{
	//	meta: {
	//		title: 'New Client'
	//	},
	//	path: '/client/new',
	//	name: 'client.new',
	//	component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue')
	//},
	//{
	//	meta: {
	//		title: 'Edit Client'
	//	},
	//	path: '/client/:id',
	//	name: 'client.edit',
	//	component: () => import(/* webpackChunkName: "client-form" */ '../views/ClientForm.vue'),
	//	props: true
	//},
	{
		path: '/full-page',
		component: () => import(/* webpackChunkName: "full-page" */ '../views/FullPage.vue'),
		children: [
			{
				meta: {
					title: 'ログイン',
					requireAuth: false,
				},
				path: '/login',
				name: 'login',
				component: Login
			},
			//{
			//	meta: {
			//		title: 'Password Recovery'
			//	},
			//	path: '/password-recovery',
			//	name: 'password-recovery',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/PasswordRecovery.vue')
			//},
			//{
			//	meta: {
			//		title: 'Error v.1'
			//	},
			//	path: '/error-in-card',
			//	name: 'error-in-card',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Error.vue')
			//},
			//{
			//	meta: {
			//		title: 'Error v.2'
			//	},
			//	path: '/error-simple',
			//	name: 'error-simple',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/Error.vue'),
			//	props: { isInCard: false }
			//},
			//{
			//	meta: {
			//		title: 'Lock screen'
			//	},
			//	path: '/lock-screen',
			//	name: 'lock-screen',
			//	component: () => import(/* webpackChunkName: "full-page" */ '../views/full-page/LockScreen.vue')
			//}
		]
	}
]

const router = new VueRouter({
	base: process.env.BASE_URL,
	mode: 'history',
	routes,
	scrollBehavior (to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return { x: 0, y: 0 }
		}
	}
})

router.beforeEach((to, from, next) => {
	if (to.matched.some(record => record.meta.requireAuth)) {
		// 認証トークン存在チェック
		if (!store.state.token) {
			next({ path: '/login' });
		}

		// アクセスコントロール
		//if (store.state.statusId === -1) {
		//	let auth = new Auth();
		//	auth.logout();
		//	next({ path: '/login' });
		//}
	}

	next();
})

export default router
