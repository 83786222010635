class Consts {
	constructor() {
		this.APP_NAME = '爆速LP';
		this.MSG_CONFIRM_REGISTER = 'データを登録します。よろしいですか？';
		this.MSG_CONFIRM_DELETE = '選択したデータを削除します。よろしいですか？';
		this.TABLE_PER_PAGE = 10;
		this.COMPANY_NAME = '爆速LP';
		this.MSG_INPUT_ERROR_REQUIRED = '必須入力です';
	}
}

export default Consts
